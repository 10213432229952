<template>
  <v-card elevation="0" class="mb-5">
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <h4 class="mt-5">Order Fulfillment</h4>
    <v-card-text class="px-0">
      <v-form ref="form" v-model="valid">
        <v-row class="mt-3">
          <!-- Fulfillment Method Selector -->
          <v-col cols="6">
            <v-select
              v-model="fulfillment.method"
              @change="resetFulfillmentDetails"
              :items="fulfillmentMethods"
              label="Fulfillment Method"
              outlined
              dense
              :rules="[rules.required]"
            ></v-select>
          </v-col>

          <!-- Delivery Details -->
          <v-col cols="6" v-if="fulfillment.method === 'Delivery'">
            <v-text-field
              v-model.number="fulfillment.price"
              label="Delivery Fee"
              outlined
              dense
              type="number"
              prefix="R"
              :rules="[rules.positiveNumber]"
            ></v-text-field>
          </v-col>

          <!-- Collection Details -->
          <v-col cols="6" v-if="fulfillment.method === 'Collection'">
            <v-select
              v-model="fulfillment.location"
              :items="filteredCollectionLocations"
              label="Collection Location"
              item-text="branch_name"
              item-value="branch_name"
              cache-items
              outlined
              dense
              :rules="[rules.required]"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: Object,
    collectionLocations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: false,
      fulfillment: {
        method: "Unspecified",
        price: 0,
        location: this.defaultCollectionLocation(),
      },
      fulfillmentMethods: ["Unspecified", "Delivery", "Collection"],
      
      rules: {
        required: value => !!value || "Required.",
        positiveNumber: value => value >= 0 || "Must be a positive number.",
      },
    };
  },
  computed: {
    cleanedFulfillmentData() {
      const { method, price, location } = this.fulfillment;
      if (method === "Unspecified") {
        return { method, price };
      } else if (method === "Delivery") {
        return { method, price };
      } else if (method === "Collection") {
        return { method, location, price };
      }
      return {};
    },
    filteredCollectionLocations() {
      return this.collectionLocations.filter(x => x.branch_name !== 'Online Store');
    }
  },
  methods: {
    defaultCollectionLocation() {
      const location = this.collectionLocations.find(location => location.branch_name === 'Montague Gardens');
      return location ? location.branch_name : null;
    },
    resetFulfillmentDetails() {
      if (!this.fulfillment.method === 'delivery') {
        this.$set(this.fulfillment, 'price', 0);
      }
      if (!this.fulfillment.method === 'collection') {
        this.$set(this.fulfillment, 'location', this.defaultCollectionLocation());
      }
      this.fulfillment.price = 0;
      this.fulfillment.location = this.defaultCollectionLocation();
    },
  },
  created() {
    // for edit mode
    if(this.value?.method) {
      this.fulfillment = this.value;
    }
  },
  watch: {
    fulfillment: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit('input', this.cleanedFulfillmentData);
      }
    }
  },
};
</script>

<style scoped>
.text-orange {
  color: orange;
  font-weight: bold;
}
</style>